






















import Vue from "vue";
import Component from "vue-class-component";
import VButton from "@/components/VButton.vue";

@Component({
  components: {
    VButton
  }
})
export default class NewModal extends Vue {
  closeModal() {
    this.$emit("close-modal");
  }

  logout() {
    this.$emit("logout");
  }
}
