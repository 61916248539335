



















































import AuthService from "@/services/AuthService";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class NavigationBar extends Vue {
  @Prop({ required: true })
  open!: boolean;
  @Prop({ required: true })
  hasProducts!: boolean;
  privileges: string | null = ''

  created() {
    this.privileges = AuthService.getPrivileges()
    console.log("🚀 ~ NavigationBar ~ created ~ privileges:", this.privileges)
  }

  openScanner() {
    this.$emit("open-scanner");
  }

  closeScanner() {
    this.$emit("close-scanner");
  }

  logout() {
    this.$emit("logout");
  }

  goToStadistic() {
    if (this.hasProducts) {
      this.$emit("stadistic-page");
    }
  }
  goToManual() {
    this.$emit("manual-page");
  }
  goToReports() {
    this.$emit("reports");
  }
}
