



















import Vue from "vue";
import Component from "vue-class-component";
import VButton from "@/components/VButton.vue";

@Component({
  components: {
    VButton
  }
})
export default class Manual extends Vue {
  refCode = "";

  get getRefCode() {
    return this.refCode;
  }
  set getRefCode(value) {
    this.refCode = value;
  }

  addRefCode() {
    this.$emit("add-refcode", this.refCode);
    this.refCode = "";
  }
}
