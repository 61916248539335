








































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VButton from "@/components/VButton.vue";
import { RedeemError, Redeem } from "@/models/redeem";

@Component({
  components: {
    VButton
  }
})
export default class NewScanList extends Vue {
  @Prop({ required: false })
  product!: Redeem | RedeemError;
  @Prop({ required: false })
  productLength!: number;
  @Prop({ required: false })
  trySameVoucher!: boolean;

  continueScan() {
    // this.$emit("continue-scan");
  }

  forceRedeem() {
    this.$emit("force-redeem", this.product.qrCode);
  }
}
