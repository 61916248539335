
































































import Vue from "vue";
import Component from "vue-class-component";
import RedeemService from "@/services/RedeemService";
import VButton from "@/components/VButton.vue";
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';



@Component({
  components: {
      VueDatePicker,
      VButton
  }
})
export default class Reports extends Vue {
  minDate: any = new Date()
  maxDate: any = new Date()
  listScopeIDs: any[] = []
  listSalesRedeemed: any[] = []
  listBarcodes: any[] = []
  scopeId: any = "" 
  emptyScope = false
  init = false
  loading = false
  openBardcodeModal = false

  created() {
      this.minDate = this.minDate.getFullYear().toString() + '-' + ('0' + (this.minDate.getMonth()+1)).slice(-2) + '-' + ('0' + this.minDate.getDate()).slice(-2)
      this.maxDate = this.maxDate.getFullYear().toString() + '-' + ('0' + (this.maxDate.getMonth()+1)).slice(-2) + '-' + ('0' + this.maxDate.getDate()).slice(-2)
      this.getScopes()
  }

  onSelectScope(e: any) {
    if(+e.target.value !== 0){
      this.scopeId = +e.target.value
      this.getList()
    } else {
      this.scopeId = ""
      this.listSalesRedeemed = []
    }
  }

  changeMinDate(minDate: string) {
    this.minDate = minDate
    this.getScopes()
  }

  changeMaxDate(maxDate: string) {
    this.maxDate = maxDate
    this.getScopes()
  }

  getList() {
    if(this.scopeId !== 0) {
      this.loading = true
      RedeemService.getRedeemList(this.scopeId,this.minDate, this.maxDate)
      .then((response: any) => {
        if(response.data.length > 0) {
          this.loading = false
          this.listSalesRedeemed = response.data
        } else {
          //  error lista vacia
        }
      })
      .catch((err: any) => {
        console.log('error', err)
      });
    } else {
      //   mostrar error seleccione scope
    }
  }

  isDateEqualOrBefore(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    return d1 <= d2;
  }

  getScopes() {
  this.emptyScope = false
  if(this.isDateEqualOrBefore(this.minDate, this.maxDate)){
    RedeemService.getScopeList(this.minDate, this.maxDate)
      .then((response: any) => {
        if(response.data.length > 0) {
          this.init = true
          this.listScopeIDs = response.data
          if(!this.listScopeIDs.find((scope:any) => scope.id === this.scopeId)){
            this.scopeId = ""
            this.listSalesRedeemed = []
          } else {
            this.getList()
          }
        } else {
          this.init = true
          this.emptyScope = true
          this.scopeId = ""
          this.listSalesRedeemed = []
        }
      })
      .catch((err: any) => {
        this.init = true
        console.log('error', err)
      });
    } else {
      this.minDate = this.maxDate
      this.getScopes()
    }
  } 

  onOpenBardcodeByScope(sale: any) {
    this.openBardcodeModal = true
    this.listBarcodes = []
    RedeemService.getBarcodeRedeemByScope(this.scopeId,this.minDate, this.maxDate, sale.productId, sale.productOptionId, sale.personTypeId)
      .then((response: any) => {
        if(response.status === 200) {
          this.listBarcodes = response.data
        } else {
          this.listBarcodes = []
        }
      })
      .catch((err: any) => {
        this.listBarcodes = []
      })
  }

  closeModal(){
    this.openBardcodeModal = false
  }
}
