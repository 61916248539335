import AxiosService from '@/services/AxiosService';
import API_URL from '@/settings/Setting';
import { RedeemValue } from '@/models/redeem';

const RedeemService = {
  redeemBarcode(data: RedeemValue) {
    const url = API_URL.REDEEM;
    return AxiosService.put(url, data);
  },

  getOfflineSale() {
    const url = API_URL.OFFLINE;
    return AxiosService.get(url);
  },

  getRedeemList(scopeId: number, startRedeemedDate: string, endRedeemedDate: string) {
    const url = API_URL.REDEEM_LIST
    .replace(':scopeId', scopeId.toString())
    .replace(':startRedeemedDate', startRedeemedDate)
    .replace(':endRedeemedDate', endRedeemedDate)
    return AxiosService.get(url);
  },

  getBarcodeRedeemByScope(scopeId: number, startRedeemedDate: string, endRedeemedDate: string, productId: number, productOptionId: number, personTypeId: number) {
    const url = API_URL.BARCODES_REDEEMED_BYSCOPE
    .replace(':scopeId', scopeId.toString())
    .replace(':startRedeemedDate', startRedeemedDate)
    .replace(':endRedeemedDate', endRedeemedDate)
    .replace(':productId', productId.toString())
    .replace(':productOptionId', productOptionId.toString())
    .replace(':personTypeId', personTypeId.toString())
    return AxiosService.get(url);
  },

  getScopeList(startRedeemedDate: string, endRedeemedDate: string) {
    const url = API_URL.SCOPE_LIST
    .replace(':startRedeemedDate', startRedeemedDate)
    .replace(':endRedeemedDate', endRedeemedDate)
    return AxiosService.get(url);
  }
};

export default RedeemService;