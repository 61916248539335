










































import Vue from "vue";
import Component from "vue-class-component";
import AuthService from "@/services/AuthService";

@Component({})
export default class Header extends Vue {
  locales = process.env.VUE_APP_I18N_LOCALES_AVAILABLES.split(",");
  local = "";
  theme: string | null = "";

  created() {
    this.local = this.$i18n.locale;
    this.theme = AuthService.getTheme();
  }

  // logout() {
  //   AuthService.logout();
  //   this.$router.push("/");
  // }

  switchLocale(locale: string) {
    if (this.$i18n.locale !== locale) {
      AuthService.setLanguage(locale);
      this.$i18n.locale = locale;
      this.local = locale;
    }
  }
}
