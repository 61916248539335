












































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { RedeemError, Redeem } from "@/models/redeem";
import VButton from "@/components/VButton.vue";

@Component({
  components: {
    VButton
  }
})
export default class Stadistic extends Vue {
  @Prop({ required: true })
  listProducts!: Redeem[];
  auxListProducts: Redeem[] = [];
  refCode = "";
  radioproductStatus = "all";

  created() {
    this.auxListProducts = this.listProducts;
  }

  get getRefCode() {
    return this.refCode;
  }
  set getRefCode(value) {
    this.refCode = value;
    this.auxListProducts = this.listProducts.filter((p: Redeem) =>
      p.qrCode.includes(this.refCode)
    );
  }

  deleteProductList() {
    this.auxListProducts = [];
    this.$emit("delete-list");
  }
  forceRedeem(qrCode: string) {
    this.$emit("force-redeem", qrCode);
  }

  filterProductByStatus(e: any) {
    this.refCode = "";
    this.radioproductStatus = e.target.value;
    switch (e.target.value) {
      case "valid":
        this.auxListProducts = this.listProducts.filter(
          (p: Redeem) => p.status.textCode === "valid"
        );
        break;

      case "expired":
        this.auxListProducts = this.listProducts.filter(
          (p: Redeem) => p.status.textCode === "expired"
        );
        break;

      case "canceled":
        this.auxListProducts = this.listProducts.filter(
          (p: Redeem) => p.status.textCode === "canceled"
        );
        break;

      case "redeemed":
        this.auxListProducts = this.listProducts.filter(
          (p: Redeem) => p.status.textCode === "redeemed"
        );
        break;

      default:
        this.auxListProducts = this.listProducts;
        break;
    }
  }
}
