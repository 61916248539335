






































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import VButton from "@/components/VButton.vue";

@Component({
  components: {
    VButton
  }
})
export default class Modal extends Vue {
  @Prop({ required: true })
  headerText!: string;
  @Prop({ required: true })
  bodyText!: string;
  @Prop({ required: false })
  redeemModal!: boolean;
  message = "";
  messageError = false;

  closeModal() {
    this.$emit("close-modal");
  }

  closeModalForm() {
    if ((this.message && this.redeemModal) || !this.redeemModal) {
      this.$emit("close-modal", this.message);
    } else {
      this.messageError = true;
    }
  }
}
