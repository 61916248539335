



























































































import Vue from "vue";
import Component from "vue-class-component";
import RedeemService from "@/services/RedeemService";
import {
  Redeem,
  RedeemError,
  RedeemStatus,
  RedeemValue
} from "@/models/redeem";

// import ScanBottomPanel from "@/components/ScanBottomPanel.vue";
import NewScanBottomPanel from "@/components/NewScanBottomPanel.vue";
import VButton from "@/components/VButton.vue";
import Header from "@/components/Header.vue";
import Manual from "@/components/Manual.vue";
import Reports from "@/components/Reports.vue";
import Stadistic from "@/components/Stadistic.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import Modal from "@/components/Modal.vue";
import NewModal from "@/components/NewModal.vue";
import Notification from "@/components/Notification.vue";
import AuthService from "@/services/AuthService";

@Component({
  components: {
    // ScanBottomPanel,
    NewScanBottomPanel,
    VButton,
    Header,
    NavigationBar,
    Modal,
    NewModal,
    Notification,
    Stadistic,
    Manual,
    Reports
  }
})
export default class Scanner extends Vue {
  newQRDecoded = "";
  errorDecoded = false;
  listProducts: any[] = [];
  storageListProducts: any[] = [];
  newProduct: any = null;
  canContinueScan = true;
  trySameVoucher = false;
  stadisticPage = false;
  manualPage = false;
  reportPage = false;
  hasProducts = false;
  open = false;
  redeem: RedeemValue = {
    forceRedemption: false,
    comment: "",
    barcode: ""
  };
  openModal = false;
  openNewModal = false;
  scanned = false;
  expiredForced = false;
  disabledCamera = false;
  vouchersError = 0;

  created() {
    // Future offline
    /* RedeemService.getOfflineSale().then(res => {
      console.log(res);
    }); */
    const posibleList: string | null = AuthService.getListProducts();
    if (posibleList) {
      this.storageListProducts = JSON.parse(posibleList);
      this.hasProducts = true;
    }
  }

  getQRDecoded(data: any) {
    if (data && data.detail) {
      if (data.detail !== this.newQRDecoded) {
        this.trySameVoucher = false;
        this.errorDecoded = false;
        this.newQRDecoded = data.detail;
        this.notForceRedeem();
      } else {
        this.trySameVoucher = true;
      }
    } else {
      this.errorDecoded = true;
    }
  }

  openScanner() {
    this.stadisticPage = false;
    this.manualPage = false;
    this.reportPage = false;
    this.open = true;
  }

  navigationToStadistic() {
    this.disconnectCamera();
    this.stadisticPage = true;
  }

  navigationToManual() {
    this.stadisticPage = false;
    this.reportPage = false;
    this.manualPage = true;
  }

  navigationToReport() {
    this.stadisticPage = false;
    this.manualPage = false;
    this.reportPage = true;
  }

  notForceRedeem() {
    // this.canContinueScan = false;  For admin continue scanning
    this.redeem.forceRedemption = false;
    this.redeem.comment = "";
    this.redeem.barcode = this.newQRDecoded;
    this.expiredForced = false;
    this.redeemCode();
  }

  openModalRedeem(qrCode: string) {
    this.openModal = true;
    this.newQRDecoded = qrCode;
  }

  forceRedeem(message: string) {
    this.openModal = false;
    this.expiredForced = false;
    if (message) {
      this.redeem.forceRedemption = true;
      this.redeem.comment = message;
      this.redeem.barcode = this.newQRDecoded;
      this.expiredForced = true;
      this.redeemCode();
    }
  }

  redeemCode() {
    // TODO: mandar como objeto, nada de url
    RedeemService.redeemBarcode(this.redeem).then(
      res => {
        const newProductRedeem: Redeem = res.data;
        if (this.newQRDecoded.includes("CSS")) {
          newProductRedeem.qrCode = this.newQRDecoded;
        } else if (res.data.reference) {
          newProductRedeem.qrCode = res.data.reference;
        }
        // - Válido, Expirado, Cancelado, Ya redimido, Aún no válido
        if (this.expiredForced) {
          this.listProducts.filter(element => {
            if (element.qrCode === this.newQRDecoded) {
              element.forced = true;
            }
            return element;
          });
        }
        this.scanned = true;
        this.listProducts.push(newProductRedeem);
        this.storageListProducts.push(newProductRedeem);
        AuthService.setListProducts(JSON.stringify(this.storageListProducts));
        this.hasProducts = true;
        this.newProduct = newProductRedeem;
        if (
          newProductRedeem &&
          newProductRedeem.status &&
          newProductRedeem.status.error
        ) {
          this.vouchersError++;
        }
        setTimeout(() => {
          this.scanned = false;
        }, 2000);
      },
      err => {
        // - No válido
        if (err.response && err.response.data) {
          if (AuthService.getExpirationToken() && err.response.status === 403) {
            this.disabledCamera = true;
            // This is so that the camera can be stop before
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }
          const codeError: RedeemError | RedeemStatus = {
            error: true,
            qrCode: this.newQRDecoded.includes("CSS") ? this.newQRDecoded : "",
            status: err.response.data.status.length
              ? err.response.data.status.code
              : err.response.data.status,
            message: err.response.data.status.length
              ? err.response.data.status.textCode
              : err.response.data.message
          };
          this.scanned = true;
          this.vouchersError++;
          this.listProducts.push(codeError);
          setTimeout(() => {
            this.scanned = false;
          }, 500);
        }
      }
    );
  }

  continueScan() {
    this.newProduct = null;
    this.canContinueScan = true;
  }

  disconnectCamera() {
    this.open = false;
    this.disabledCamera = true;
  }

  logout() {
    this.openNewModal = true;
  }

  logoutFromModal() {
    AuthService.logout();
    this.$router.push("/");
  }

  closeFromModal() {
    this.openNewModal = false;
  }

  deleteProductList() {
    AuthService.removeListProducts();
    this.hasProducts = false;
    this.storageListProducts = [];
  }

  addRefcode(manualCode: string) {
    this.redeem = {
      forceRedemption: false,
      comment: "Manual",
      barcode: manualCode
    };
    this.redeemCode();
  }
}
