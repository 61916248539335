















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import NewScanList from "./NewScanList.vue";

import { RedeemError, Redeem } from "@/models/redeem";

@Component({
  components: {
    NewScanList
  }
})
export default class NewScanBottomPanel extends Vue {
  @Prop({ required: false })
  newProduct!: any;
  @Prop({ required: false })
  productLength!: number;
  @Prop({ required: false })
  trySameVoucher!: boolean;
  opened = false;

  forceRedeem(qrCode: string) {
    this.$emit("force-redeem", qrCode);
  }

  continueScan() {
    this.$emit("continue-scan");
  }
}
